import React from 'react';


import './LandingPage.css';

import IPhoneMock from './components/PhoneMock';
import DownloadIconContainer from './components/DownloadIconContainer';

const LandingPage: React.FC = () => {

  const features = [
    { name: 'Home Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/home.jpg` },
    { name: 'Feed Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/feed.jpg` },
    { name: 'Media Manage Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/media.jpg` },
    { name: 'Account Screen', imageUrl: `${process.env.PUBLIC_URL}/mocks/account.jpg` },
  ];

  return (
    <div className="container">
      <div className="content">
        <h1 className="title">Welcome to Kai Social</h1>
        <p className="subtitle">Explore, Connect, and Share with the LGBTQ+ Community</p>
        
        <div className="feature-container">
          {features.map((feature, index) => (
            <IPhoneMock key={index} name={feature.name} imageUrl={feature.imageUrl} />
          ))}
        </div>
        <DownloadIconContainer />
      </div>

      <footer className="footer">
        <p>&copy; 2024 Dows Lab. All rights reserved. 
        </p>
        <p>
        <a href="/privacy" className="footer-link">Privacy Policy</a> | 
          <a href="/terms-of-service" className="footer-link">Terms of Service</a> | 
          support@getkaiapp.com
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;